/* ##remfixer: 1080 */

.TabContainer {
  width: 100%;
  margin-bottom: 0.333333rem;
}

.TabContainer .ImgBlockNormal {
  width: 3.333333rem;
  height: 5.925926rem;
  margin: 0 0.111111rem 0 0;
}

.TabContainer .block_head-more {
  padding-right: 0.537037rem;
  background: url(~assets/images/order.png) no-repeat right center;
  background-size: 0.444444rem 0.444444rem;
}

.head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.444444rem;
}

.head_item {
  width: 100%;
  text-align: center;
}

.head_item.active .head_text:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: -1px;
  left: 0;
  background-color: #fc5b23;
}

.head_item.active .head_text {
  color: #FC5B23;
}

.head_text {
  position: relative;
  display: inline-block;
  line-height: 0.944444rem;
  font-size: 0.388889rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  padding: 0 0.166667rem;
}

.appInfo {
  padding: 0 0.444444rem;
  margin-bottom: 0.333333rem;
}

.appInfo_text {
  width: 100%;
  margin-top: 0.277778rem;
  text-align: center;
}

.appInfo_title {
  max-width: 100%;
  line-height: 0.611111rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  padding: 0 0.574074rem;
  background: url("../assets/images/Combined Shape.png") no-repeat left center;
  background-size: 0.388889rem 0.296296rem;
  font-size: 0.5rem;
  color: #000000;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0.277778rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
}

.appInfo_title:after {
  position: absolute;
  content: "";
  width: 0.388889rem;
  height: 100%;
  top: 0;
  right: 0;
  background: url("../assets/images/Combined Shape.png") no-repeat left center;
  background-size: 0.388889rem 0.296296rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}