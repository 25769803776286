/* ##remfixer: 1080 */

.errBlock {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.errBlock img {
  display: block;
  width: 4.444444rem;
  height: 4.444444rem;
  margin: 0 auto;
}

.errBlock p {
  text-align: center;
  font-size: 0.444444rem;
  color: rgba(0, 0, 0, 0.25);
  font-weight: 500;
  margin-bottom: 0.462963rem;
}