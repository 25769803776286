/* ##remfixer: 1080 */
.comment-stats[data-v-4cd4d7e8] {
  padding: 0 0.666667rem;
  margin-bottom: 0.333333rem;
}
.comment-stats .stats-left[data-v-4cd4d7e8] {
  float: left;
  width: 1.62037rem;
  margin-right: 0.509259rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.comment-stats .stats-left p[data-v-4cd4d7e8] {
  width: 100%;
  line-height: 1.481481rem;
  font: inherit;
  font-size: 0.925926rem;
  font-weight: bold;
  text-align: center;
  color: #000;
  letter-spacing: 0;
}
.comment-stats .stats-left .game-star[data-v-4cd4d7e8] {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.comment-stats .stats-right[data-v-4cd4d7e8] {
  float: left;
  font-size: 0.222222rem;
  color: rgba(0, 0, 0, 0.4);
}
.comment-stats .stats-right .bar[data-v-4cd4d7e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.111111rem;
}
.comment-stats .stats-right .bar .percentage-bar[data-v-4cd4d7e8] {
  width: 5rem;
  position: relative;
  margin: 0 0.111111rem 0 0.092593rem;
  height: 0.166667rem;
}
.comment-stats .stats-right .bar .percentage-bar .bar-down[data-v-4cd4d7e8],
.comment-stats .stats-right .bar .percentage-bar .bar-up[data-v-4cd4d7e8] {
  float: left;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.comment-stats .stats-right .bar .percentage-bar .bar-up[data-v-4cd4d7e8] {
  background-color: #55ba30;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}
.comment-stats .stats-right .bar .percentage[data-v-4cd4d7e8] {
  display: inline-block;
  width: 0.5rem;
  text-align: right;
}
.comment-stats[data-v-4cd4d7e8]::after {
  content: "";
  display: block;
  clear: both;
}
/* ##remfixer: 1080 */
.errBlock[data-v-d20820d0] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
.errBlock img[data-v-d20820d0] {
  display: block;
  width: 4.444444rem;
  height: 4.444444rem;
  margin: 0 auto;
}
.errBlock p[data-v-d20820d0] {
  text-align: center;
  font-size: 0.444444rem;
  color: rgba(0, 0, 0, 0.25);
  font-weight: 500;
  margin-bottom: 0.462963rem;
}
/* ##remfixer: 1080 */
.TabContainer[data-v-c0c32b62] {
  width: 100%;
  margin-bottom: 0.333333rem;
}
.TabContainer .ImgBlockNormal[data-v-c0c32b62] {
  width: 3.333333rem;
  height: 5.925926rem;
  margin: 0 0.111111rem 0 0;
}
.TabContainer .block_head-more[data-v-c0c32b62] {
  padding-right: 0.537037rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAARVBMVEUAAAD9XST8XCT9XCT9XCP9WyP9XCT9XCT/XSf9XCT/XiX/ZS38XCP9WyT9XCP/XiT/XCb8XCT8WyP9WyT9WyT/Xib8WyMKaBmgAAAAFnRSTlMAVaqA+uikk0LzHQnKhnRPPce2jHBJbFn9AwAAAIBJREFUSMft1LkKwCAQRdExu5p9ef//qUEIpBiNTifB20x1QCwelfLL7vp0d2rAawcOLkC728BXx4EC4O7oBXMY2EXxVgqAYAX8DwxdxetNGLTw1YuB+EmZf+tRubZkYPCUCqh+gVSQVJBUkFSQSEiHrI5NJReRMeYp9TX3pXy6AQjeGmG99uAsAAAAAElFTkSuQmCC) no-repeat right center;
  background-size: 0.444444rem 0.444444rem;
}
.head[data-v-c0c32b62] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.444444rem;
}
.head_item[data-v-c0c32b62] {
  width: 100%;
  text-align: center;
}
.head_item.active .head_text[data-v-c0c32b62]:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: -1px;
  left: 0;
  background-color: #fc5b23;
}
.head_item.active .head_text[data-v-c0c32b62] {
  color: #FC5B23;
}
.head_text[data-v-c0c32b62] {
  position: relative;
  display: inline-block;
  line-height: 0.944444rem;
  font-size: 0.388889rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  padding: 0 0.166667rem;
}
.appInfo[data-v-c0c32b62] {
  padding: 0 0.444444rem;
  margin-bottom: 0.333333rem;
}
.appInfo_text[data-v-c0c32b62] {
  width: 100%;
  margin-top: 0.277778rem;
  text-align: center;
}
.appInfo_title[data-v-c0c32b62] {
  max-width: 100%;
  line-height: 0.611111rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  padding: 0 0.574074rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAgBAMAAACWdvcOAAAAElBMVEUAAAAAAAAAAAAAAAAAAAAAAADgKxmiAAAABnRSTlMaAAYUEAyx2yxmAAAA4ElEQVQoz5WRvRKCMBCE14g9BOjJOPbB8QHEwT46vv+zmPvFQgtTwM6S/e6OQ8env3V+LqlTtyCZOSKoG7H3qxNmdXsEdzOMcMVs5kAxCABpAwR12w8sKAYBBAdwDNzLhi0cA6McG4GgbgY9tUXsyGUAHODuBCdEOCGTdID2MIDOIjOSbMid2N0bAOC/k1UyQG+AAJwTgODAACkSTQYUk49uNHlANjkTVsEmaKbW9T/ud+6PHjxWp3AYRvte3WIx2JgLrdKGh9IaWYVWqLgjVU2yC+roLNs8rXff8Wt91tcbfAUhI0sGTnsAAAAASUVORK5CYII=) no-repeat left center;
  background-size: 0.388889rem 0.296296rem;
  font-size: 0.5rem;
  color: #000000;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0.277778rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
}
.appInfo_title[data-v-c0c32b62]:after {
  position: absolute;
  content: "";
  width: 0.388889rem;
  height: 100%;
  top: 0;
  right: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAgBAMAAACWdvcOAAAAElBMVEUAAAAAAAAAAAAAAAAAAAAAAADgKxmiAAAABnRSTlMaAAYUEAyx2yxmAAAA4ElEQVQoz5WRvRKCMBCE14g9BOjJOPbB8QHEwT46vv+zmPvFQgtTwM6S/e6OQ8env3V+LqlTtyCZOSKoG7H3qxNmdXsEdzOMcMVs5kAxCABpAwR12w8sKAYBBAdwDNzLhi0cA6McG4GgbgY9tUXsyGUAHODuBCdEOCGTdID2MIDOIjOSbMid2N0bAOC/k1UyQG+AAJwTgODAACkSTQYUk49uNHlANjkTVsEmaKbW9T/ud+6PHjxWp3AYRvte3WIx2JgLrdKGh9IaWYVWqLgjVU2yC+roLNs8rXff8Wt91tcbfAUhI0sGTnsAAAAASUVORK5CYII=) no-repeat left center;
  background-size: 0.388889rem 0.296296rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* ##remfixer: 1080 */
.GameDetails {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.GameDetails .mabh_text {
  width: 4.814815rem;
  margin-top: 0.37037rem;
}
.GameDetails .mabh_app-title {
  margin-bottom: 0.166667rem;
}
.GameDetails .block_head-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.GameDetails .coupon {
  margin-top: 0.222222rem;
  margin-bottom: 0;
}
.GameDetails .appInfo .block_list {
  padding: 0;
}
#downloadApp {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 1.203704rem;
  line-height: 1.203704rem;
  border-radius: 1.203704rem;
  border: 1px solid rgba(252, 91, 35, 0.35);
  margin: 0.240741rem 0 1.111111rem;
  text-align: center;
  background-color: rgba(252, 91, 35, 0.05);
  overflow: hidden;
  color: #000;
  font-size: 0.388889rem;
}
#downloadApp .btn {
  width: 100%;
  height: 100%;
  color: #000;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  font-size: 0.388889rem;
  line-height: 1.203704rem;
}

/*# sourceMappingURL=GameDetail-vue.c64b8e0a.css.map*/