/* ##remfixer: 1080 */

.comment-stats {
  padding: 0 0.666667rem;
  margin-bottom: 0.333333rem;
}

.comment-stats .stats-left {
  float: left;
  width: 1.62037rem;
  margin-right: 0.509259rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.comment-stats .stats-left p {
  width: 100%;
  line-height: 1.481481rem;
  font: inherit;
  font-size: 0.925926rem;
  font-weight: bold;
  text-align: center;
  color: #000;
  letter-spacing: 0;
}

.comment-stats .stats-left .game-star {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.comment-stats .stats-right {
  float: left;
  font-size: 0.222222rem;
  color: rgba(0, 0, 0, 0.4);
}

.comment-stats .stats-right .bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.111111rem;
}

.comment-stats .stats-right .bar .percentage-bar {
  width: 5rem;
  position: relative;
  margin: 0 0.111111rem 0 0.092593rem;
  height: 0.166667rem;
}

.comment-stats .stats-right .bar .percentage-bar .bar-down,
.comment-stats .stats-right .bar .percentage-bar .bar-up {
  float: left;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.comment-stats .stats-right .bar .percentage-bar .bar-up {
  background-color: #55ba30;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.comment-stats .stats-right .bar .percentage {
  display: inline-block;
  width: 0.5rem;
  text-align: right;
}

.comment-stats::after {
  content: "";
  display: block;
  clear: both;
}